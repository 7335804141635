import { Link } from "gatsby"
import React from "react"

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="footer-widgets">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="foot-about">
                <p></p>
                <ul className="d-flex flex-wrap align-items-center">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                </ul>
              </div>
              {/* .foot-about */}
            </div>
            {/* .col */}
            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
              <h2>Useful Links</h2>
              <ul>
                <li>
                  <a
                    href="http://www.iemoutreach.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    IEM (India)
                  </a>
                </li>
                <li>
                  <Link to="/donate">Donate</Link>
                </li>
              </ul>
            </div>
            {/* .col */}
            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
              <div className="foot-latest-news"></div>
              {/* .foot-latest-news */}
            </div>
            {/* .col */}
            <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
              <div className="foot-contact">
                <h2>Contact</h2>
                <ul>
                  <li>
                    <i className="fa fa-phone" />
                    <span>0437 400 009</span>
                  </li>
                  <li>
                    <i className="fa fa-envelope" />
                    <span>national.director@fiema.org</span>
                  </li>
                </ul>
              </div>
              {/* .foot-contact */}
              <div className="subscribe-form">{/* .flex */}</div>
              {/* .search-widget */}
            </div>
            {/* .col */}
          </div>
          {/* .row */}
        </div>
        {/* .container */}
      </div>
      {/* .footer-widgets */}
      <div className="footer-bar">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="m-0">
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                Copyright © {new Date().getFullYear()} All rights reserved |
                This
                <i className="fa fa-heart-o" aria-hidden="true" /> by
                <a href="https://colorlib.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Colorlib
                </a>
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
