import { Link } from "gatsby"
import React, { useState } from "react"
import logo from "../images/fiema-logo.png"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  return (
    <div className="nav-bar">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
            <div className="site-branding d-flex align-items-center">
              <Link to="/">
                <img height="70px" className="d-block" src={logo} alt="logo" />
              </Link>
            </div>

            <nav
              className={'site-navigation d-flex justify-content-end align-items-center' + (isOpen ? ' show' : '')}
            >
              <ul className="d-flex flex-column flex-lg-row justify-content-lg-end align-content-center">
                <li>
                  <Link to="/" activeClassName="active">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" activeClassName="active">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/projects" activeClassName="active">
                    Projects
                  </Link>
                </li>
                <li>
                  <Link to="/prayer" activeClassName="active">
                    Prayer
                  </Link>
                </li>
                <li>
                  <Link to="/contact" activeClassName="active">
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>

            <div
              className={'hamburger-menu d-lg-none' + (isOpen ? ' open' : '')}
              onClick={toggleMenu}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
