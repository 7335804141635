import { Link } from "gatsby"
import React from "react"

const HeaderBarTop = () => {
  return (
    <div className="top-header-bar">
      <div className="container">
        <div className="row flex-wrap justify-content-center justify-content-lg-between align-items-lg-center">
          <div className="col-12 col-lg-8 d-none d-md-flex flex-wrap justify-content-center justify-content-lg-start mb-3 mb-lg-0">
            <div className="header-bar-email">
              MAIL:{" "}
              <a href="mailto:national.director@fiema.org">national.director@fiema.org</a>
            </div>
            <div className="header-bar-text">
              <p>
                <span>0437 400 009</span> National Director: Philip Patlur
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-4 d-flex flex-wrap justify-content-center justify-content-lg-end align-items-center">
            <div className="donate-btn">
              <Link to="/donate">Donate Now</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderBarTop
